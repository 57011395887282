import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { update_Section, removeSection } from "../../actions";

import { s3Upload } from "../../libs/awsLib";
import config from "../../config";
import s3config from "../../s3config";
import shorthash from "shorthash";

import {
  Accordion,
  Icon,
  Form,
  Button,
  Divider,
  Grid,
  Message,
  Radio,
  Segment,
} from "semantic-ui-react";

import TextEditor from "./components/TextEditor";
import ImageSection from "./components/ImageSection";
import LinkSection from "./components/LinkSection";
import ExternalLinkSection from "./components/ExternalLinkSection";
import SectionOptions from "./components/SectionOptions";
import CTA from "./components/CTA";
import InfoBox from "./components/InfoBox";
import Checkbox from "./components/Checkbox";

const SectionsSection = (props) => {
  const dispatch = useDispatch();

  const [title, update_title] = useState(props.section.title);
  const [text, update_text] = useState(props.section.text);
  const [type, update_type] = useState(props.section.type);
  const [data, update_data] = useState(props.section.data);

  const [image_hash, update_image_hash] = useState(props.section.image_hash);
  const [image_url, update_image_url] = useState(props.section.image_url);
  const [image_type, update_image_type] = useState(props.section.image_type);
  const [image_style, update_image_style] = useState(props.section.image_style);

  const [checkbox_text, update_checkbox_text] = useState(
    props.section.checkbox_text || null
  );
  const [original_image_key, update_original_image_key] = useState(
    props.section.original_image_key
  );

  const [alternative_link, update_alternative_link] = useState(
    props.section.alternative_link
  );
  const [page_link_type, update_page_link_type] = useState(
    props.section.page_link_type
  );
  const [destination, update_destination] = useState(props.section.destination);
  const [button_text, update_button_text] = useState(
    props.section.button_text || ""
  );

  const [colour, update_colour] = useState(props.section.colour);
  const [icon, update_icon] = useState(props.section.icon);
  const [uploadModalOpen, update_uploadModalOpen] = useState(false);

  const [ext_link, update_ext_link] = useState(props.section.ext_link);
  const [error, update_error] = useState("Wellness");

  const pages = useSelector((state) => state.pages);
  const user = useSelector((state) => state.user);

  const [section_data, update_section_data] = useState(props.section);

  useEffect(() => {
    console.log("updating");
    update_section_data(props.section);
    update_title(props.section.title);
  }, [props.section]);

  /// functions
  function handleChange(event) {
    console.log(event);
    console.log(event.target.name);
    console.log(event.target.value);
    const value = event.target.value;
    const name = event.target.name;
    switch (name) {
      case "title":
        update_title(value);
        break;
      case "text":
        update_text(value);
        break;
      case "button_text":
        update_button_text(value);
        break;
      case "ext_link":
        update_ext_link(value);
        break;
      case "checkbox_text":
        update_checkbox_text(value);
        break;
      default:
        break;
    }
  }

  function handleAccordionSelect() {
    console.log("updating acc");
    if (type === "text") {
      update_type("accordion");
    }
    if (type === "accordion") {
      update_type("text");
    }
  }

  function handlePageLinkChange(value) {
    update_page_link_type(value);
  }

  function updateAlternativeLink(value) {
    update_alternative_link(value);
    console.log("you need coffee");
  }

  function updateDestination(value) {
    update_destination(value);
  }

  function updateColour(value) {
    update_colour(value);
  }

  function updateIcon(value) {
    update_icon(value);
  }

  function handleExtLinkChange(e, { value }) {
    console.log(value);
    update_destination(value);
  }

  function nullImage(value) {
    console.log("image null");
    update_image_url(null);
    update_image_hash(null);
    update_image_type(null);
    update_image_style(null);
  }

  function updateText(data) {
    this.setState({ text: data });
  }

  // async function handleDelete(event) {
  //   await dispatch(removeSection(props.section.id));
  //   props.reindexSections();
  // }

  function moveSectionUp() {
    console.log("move section up");
    props.moveSection(props.section, "up");
  }
  function moveSectionDown() {
    props.moveSection(props.section, "down");
  }

  //   function modalUpdateIndex (event, index) {
  //   this.setState({ index_number: index });
  //   console.log(this.state.index_number);
  // };

  async function upload(event) {
    console.log("running upload function");
    update_uploadModalOpen(true);
    const file = event.target.files[0];
    console.log(file);
    const newFile = await s3Upload(file);
    console.log(newFile);
    const public_url = s3config.S3.URL + s3config.S3.EXT + newFile;
    const hash = shorthash.unique(public_url);
    update_image_url(public_url);
    update_image_hash(hash);
    update_original_image_key(newFile);
    update_uploadModalOpen(false);
  }

  async function add_image_to_section(chosen_image) {
    console.log("adding to section");
    console.log(chosen_image);
    update_image_hash(chosen_image.hash);
    update_image_url(chosen_image.image_url);
    update_image_type("external");
  }

  async function handleSubmit(event) {
    event.preventDefault();
    // if (this.state.error === true) {
    //   return;
    // }
    const params = {
      Item: {
        id: props.section.id,
        type: type,
        title: title,
        text: text,
        //index_number: index_number,
        original_image_key: original_image_key,
        destination: destination,
        colour: colour,
        icon: icon,
        ext_link: ext_link,
        data: data,
        page_link_type: page_link_type,
        alternative_link: alternative_link,
        button_text: button_text,
        image_hash: image_hash,
        image_url: image_url,
        image_type: image_type,
        image_style: image_style,
        checkbox_text: checkbox_text,
      },
    };
    console.log("Saving: ");
    console.log(params);
    console.log(user["custom:trust"]);
    dispatch(update_Section(params, user));
  }

  // render components
  function returnTextLinkSection() {
    switch (data) {
      case "none":
        return <></>;
        break;

      case "internal":
        return (
          <div>
            <LinkSection
              alternative_link={alternative_link}
              handlePageLinkChange={handlePageLinkChange}
              updateAlternativeLink={updateAlternativeLink}
              page_link_type={page_link_type}
              section={props.section}
              pages={pages}
              button_text={button_text}
              updateDestination={updateDestination}
              updateColour={updateColour}
              updateIcon={updateIcon}
              handleChange={handleChange}
            />
          </div>
        );
        break;
      case "external":
        return (
          <div>
            <ExternalLinkSection
              section={props.section}
              destination={destination}
              handleExtLinkChange={handleExtLinkChange}
              ext_link={ext_link}
              handleChange={handleChange}
              updateColour={updateColour}
              updateIcon={updateIcon}
              upload={upload}
              uploadModalOpen={uploadModalOpen}
              imageUrl={image_url}
              nullImage={nullImage.bind(this)}
              button_text={button_text}
              add_image_to_section={add_image_to_section}
            />
          </div>
        );
        break;
      default:
        break;
    }
  }

  function selector() {
    if (props.section.type === "text") {
      return (
        <div>
          <TextEditor
            text={text}
            handleChangeText={update_text}
            handleAccordionSelect={handleAccordionSelect}
            type={type}
          />
        </div>
      );
    } else if (props.section.type === "accordion") {
      return (
        <div>
          <TextEditor
            text={text}
            handleChangeText={update_text}
            handleAccordionSelect={handleAccordionSelect}
            type={type}
          />
          <div>
            <Segment secondary>
              <h5>Select which type of link you wish to include:</h5>
              <Form>
                <Form.Field>
                  Selected value: <b>{data}</b>
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="No Link"
                    name="radioGroup"
                    value="none"
                    checked={data === "none"}
                    onChange={() => update_data("none")}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Internal Link"
                    name="radioGroup"
                    value="internal"
                    checked={data === "internal"}
                    onChange={() => update_data("internal")}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="External Link"
                    name="radioGroup"
                    value="external"
                    checked={data === "external"}
                    onChange={() => update_data("external")}
                  />
                </Form.Field>
              </Form>
            </Segment>
            <Divider />

            {returnTextLinkSection()}
          </div>
        </div>
      );
    } else if (props.section.type === "image") {
      return (
        <div>
          <ImageSection
            includePreview={true}
            section={props.section}
            image_style={image_style}
            add_image_to_section={add_image_to_section}
            uploadModalOpen={uploadModalOpen}
            update_image_style={update_image_style}
          />
        </div>
      );
    } else if (props.section.type === "link") {
      return (
        <div>
          <LinkSection
            alternative_link={alternative_link}
            handlePageLinkChange={handlePageLinkChange}
            updateAlternativeLink={updateAlternativeLink}
            page_link_type={page_link_type}
            section={props.section}
            pages={pages}
            button_text={button_text}
            updateDestination={updateDestination}
            updateColour={updateColour}
            updateIcon={updateIcon}
            handleChange={handleChange}
          />
        </div>
      );
    } else if (props.section.type === "ext_link") {
      return (
        <div>
          <ExternalLinkSection
            section={props.section}
            destination={destination}
            handleExtLinkChange={handleExtLinkChange}
            ext_link={ext_link}
            handleChange={handleChange}
            updateColour={updateColour}
            updateIcon={updateIcon}
            upload={upload}
            uploadModalOpen={uploadModalOpen}
            imageUrl={image_url}
            nullImage={nullImage.bind(this)}
            button_text={button_text}
            add_image_to_section={add_image_to_section}
          />
        </div>
      );
    } else if (props.section.type === "cta") {
      return (
        <div>
          <CTA
            text={text}
            colour={colour}
            handleChange={handleChange}
            updateColour={updateColour}
          />
          <Segment secondary>
            <h5>Select which type of link you wish to include:</h5>
            <Form>
              <Form.Field>
                <Radio
                  label="No Link"
                  name="radioGroup"
                  value="none"
                  checked={data === "none"}
                  onChange={() => update_data("none")}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Internal Link"
                  name="radioGroup"
                  value="internal"
                  checked={data === "internal"}
                  onChange={() => update_data("internal")}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="External Link"
                  name="radioGroup"
                  value="external"
                  checked={data === "external"}
                  onChange={() => update_data("external")}
                />
              </Form.Field>
            </Form>
            {returnTextLinkSection()}
          </Segment>
        </div>
      );
    } else if (props.section.type === "info") {
      return (
        <div>
          <h5>If you wish to include and image, upload this below:</h5>
          <p>Note, if no image is uploaded, then it will not be displayed</p>
          <ImageSection
            section={props.section}
            image_style={image_style}
            add_image_to_section={add_image_to_section}
            uploadModalOpen={uploadModalOpen}
            update_image_style={update_image_style}
            nullImage={nullImage}
          />
          <Divider />
          <InfoBox
            text={props.section.text}
            handleChangeText={update_text}
            handleAccordionSelect={handleAccordionSelect}
            type={type}
          />
          <Segment secondary>
            <h5>Select which type of link you wish to include:</h5>
            <Form>
              <Form.Field>
                <Radio
                  label="No Link"
                  name="radioGroup"
                  value="none"
                  checked={data === "none"}
                  onChange={() => update_data("none")}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Internal Link"
                  name="radioGroup"
                  value="internal"
                  checked={data === "internal"}
                  onChange={() => update_data("internal")}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="External Link"
                  name="radioGroup"
                  value="external"
                  checked={data === "external"}
                  onChange={() => update_data("external")}
                />
              </Form.Field>
            </Form>
          </Segment>
          {returnTextLinkSection()}
        </div>
      );
    } else if (props.section.type === "checkbox") {
      return (
        <div>
          <label>
            Checkbox Text:
            <input
              name="checkbox_text"
              type="text"
              value={checkbox_text}
              onChange={(event) => handleChange(event)}
            />
          </label>
          <Divider />
          <div>
            <TextEditor
              text={text}
              handleChangeText={update_text}
              handleAccordionSelect={handleAccordionSelect}
              type={type}
            />
          </div>
          <ImageSection
            section={props.section}
            image_style={image_style}
            add_image_to_section={add_image_to_section}
            uploadModalOpen={uploadModalOpen}
            update_image_style={update_image_style}
          />
          <Segment secondary>
            <h5>Select which type of link you wish to include:</h5>
            <Form>
              <Form.Field>
                <Radio
                  label="No Link"
                  name="radioGroup"
                  value="none"
                  checked={data === "none"}
                  onChange={() => update_data("none")}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Internal Link"
                  name="radioGroup"
                  value="internal"
                  checked={data === "internal"}
                  onChange={() => update_data("internal")}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="External Link"
                  name="radioGroup"
                  value="external"
                  checked={data === "external"}
                  onChange={() => update_data("external")}
                />
              </Form.Field>
            </Form>
            {returnTextLinkSection()}
          </Segment>
        </div>
      );
    }
  }

  function removeSection(info) {
    console.log("this");
    console.log(info);
    props.handleDeleteSection(props.section);
  }

  return (
    <div>
      <Accordion styled style={{ marginTop: 10 }}>
        <Accordion.Title
          active={props.index === props.activeIndex}
          index={props.index}
          onClick={() => props.updateActiveIndex(props.index)}
        >
          <Icon name="dropdown" />
          {"(" + section_data.index_number + ") "}
          {section_data.title}
        </Accordion.Title>

        {props.index === props.activeIndex ? (
          <>
            <Accordion.Content active={props.index === props.activeIndex}>
              {error === true ? <Message error header={error} /> : <div />}
              <div>
                <Form>
                  <Form.Field>
                    <label>
                      Name:
                      <input
                        name="title"
                        type="text"
                        value={title}
                        onChange={(event) => handleChange(event)}
                      />
                    </label>
                    <Divider />
                    {selector()}
                    <Divider />
                  </Form.Field>
                </Form>
              </div>
              <Grid columns={2} relaxed="very">
                <Grid.Column>
                  <Button primary onClick={handleSubmit} content="Save" />
                </Grid.Column>
                <Grid.Column>
                  <div style={{ textAlign: "right" }}>
                    <SectionOptions
                      section={props.section}
                      handleDeleteSection={removeSection}
                      // modalUpdateIndex={modalUpdateIndex}
                      handleSubmit={handleSubmit}
                    />
                    <Button.Group>
                      <Button icon onClick={moveSectionUp}>
                        <Icon name="up arrow" />
                      </Button>
                      <Button.Or />
                      <Button icon onClick={moveSectionDown}>
                        <Icon name="down arrow" />
                      </Button>
                    </Button.Group>
                    <Button onClick={() => console.log(props.section)}>
                      Data
                    </Button>
                    {/* <Button onClick={() => console.log(this.state)}>State</Button> */}
                  </div>
                </Grid.Column>
              </Grid>
            </Accordion.Content>
          </>
        ) : (
          <></>
        )}
      </Accordion>
    </div>
  );
};

export default SectionsSection;
